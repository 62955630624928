'use client';

import 'client-only';
import { useApi } from '@prismo-io/core';
import type { AuthChangeEvent, Session } from '@supabase/supabase-js';
import { useRouter } from 'next/navigation';
import { type FC, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useAuthStateChange } from '../../hooks';

// this component handles refreshing server data when the user logs in or out
// this method avoids the need to pass a session down to child components
// in order to re-render when the user's session changes
// #elegant!

type Props = {
  serverAccessToken?: string;
};
export const SessionListener: FC<Props> = ({
  serverAccessToken
}) => {
  const router = useRouter();
  const {
    client
  } = useApi();
  useEffect(() => {
    const sessionListener = (event: AuthChangeEvent, session: Session | null) => {
      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT' || event === 'PASSWORD_RECOVERY') {
        router.refresh();
      }
    };
    const {
      data: authListener
    } = client.auth.onAuthStateChange(sessionListener);
    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [client, serverAccessToken, router]);
  return null;
};