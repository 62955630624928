import { z } from 'zod';

export const OrganizationActivity = z.enum([
  'COACHING_AND_TRAINING',
  'RECRUITMENT',
  'PROFESSIONAL_DEVELOPMENT',
  'HR_DEVELOPMENT',
  'COACHING',
  'TRAINING',
]);

export type OrganizationActivityT = z.infer<typeof OrganizationActivity>;
