import { z } from 'zod';

export const Address = z.object({
  place_id: z.string().nullable(),
  street_number: z.number().nullable(),
  street_name: z.string().nullable(),
  postcode: z.number().nullable(),
  locality: z.string().nullable(),
  nation: z.string().nullable(),
});

export type AddressT = z.infer<typeof Address>;

export const AddressUpdate = Address.partial();

export type AddressUpdateT = z.infer<typeof AddressUpdate>;
