import { z } from 'zod';

export const UserLookingForContract = z.enum([
  'INTERIM',
  'CDD',
  'CDI',
  'CDD / CDI',
]);

export type UserLookingForContractT = z.infer<typeof UserLookingForContract>;
