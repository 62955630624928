import { z } from 'zod';

export const OrganizationVolume = z.enum(
  [
    'LESS_THAN_10',
    'LESS_THAN_50',
    'BETWEEN_10_AND_50',
    'BETWEEN_50_AND_250',
    'GREATER_THAN_250',
  ],
  {
    invalid_type_error: 'Vous devez sélectionner une option.',
    required_error: 'Vous devez sélectionner une option.',
  }
);

export type OrganizationVolumeT = z.infer<typeof OrganizationVolume>;
