import { z } from 'zod';

export const CommunityCode = z.enum([
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'L6',
  'A0',
  'A1',
  'T0',
  'T1',
  'T2',
  'T3',
  'X0',
  'P0',
  'P1',
]);

export type CommunityCodeT = z.infer<typeof CommunityCode>;
